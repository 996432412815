import { LanguageSwitcher } from '@/components/ui/LanguageSwitcher';
import { Logo } from '@/components/ui/Logo';
import useMenuNavigation from '@/hooks/useNavigation';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';
import { Fragment } from 'react';

const HeaderMobileMenu = dynamic(
	() => import('@/components/ui/HeaderMobileMenu'),
	{
		ssr: false,
	}
);

const AuthSignInSignOut = dynamic(() => import('@/components/auth/Auth'), {
	ssr: false,
});

export default function Header(props) {
	const {
		languageAlternates,
		className,
		useIconAsLogo,
		useNativeNavigation = false,
	} = props;
	const navigation = useMenuNavigation();

	const popovers = [
		navigation.routes,
		navigation.companies,
		navigation.destinations,
		navigation.stations,
	].filter((item) => item.children && item.children.length > 0);

	return (
		<header className={clsx('fixed inset-x-0 top-0 z-40 bg-white', className)}>
			<nav
				className="mx-auto flex max-w-7xl items-center justify-between px-4 py-2 md:py-3.5 lg:px-8"
				aria-label="Global"
			>
				<HeaderMobileMenu
					className="flex md:hidden"
					languageAlternates={languageAlternates}
				/>
				<div className="flex flex-1 justify-center md:justify-start">
					<Link href={navigation.home.href} prefetch={false} className="block">
						{useIconAsLogo ? (
							<Image
								priority
								src={'/icons/busbuster/apple-touch-icon.png'}
								width={32}
								height={32}
								alt="BusBuster header icon"
								className="h-8 w-8 shrink-0"
							/>
						) : (
							<Logo className="h-6 w-auto" linkClassName="block" />
						)}
						<span className="sr-only">{navigation.home.name}</span>
					</Link>
				</div>

				<Popover.Group className="hidden md:flex md:gap-x-2 lg:gap-x-8">
					{popovers.map((item) => {
						return (
							<Popover key={item.name} className="relative">
								{({ open }) => (
									<>
										<Popover.Button className="flex grow cursor-pointer items-center justify-between gap-x-1 rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-gray-900 hover:bg-blue-50">
											{item.name}
											<ChevronDownIcon
												className="-mr-1 h-5 w-5 flex-none text-gray-700"
												aria-hidden="true"
											/>
										</Popover.Button>

										<Transition
											as={Fragment}
											enter="transition ease-out duration-200"
											enterFrom="opacity-0 translate-y-1"
											enterTo="opacity-100 translate-y-0"
											leave="transition ease-in duration-150"
											leaveFrom="opacity-100 translate-y-0"
											leaveTo="opacity-0 translate-y-1"
										>
											<Popover.Panel className="absolute inset-x-0 top-full z-10 mt-2 w-max rounded-xl bg-white p-2 shadow-lg ring-1 ring-gray-900/5">
												{({ close }) =>
													item?.children?.map((child) =>
														useNativeNavigation ? (
															<a
																onClick={() => close()}
																key={child.name}
																href={child.href}
																className="block cursor-pointer whitespace-nowrap rounded-lg px-3 py-2 text-sm font-semibold leading-6 text-gray-900 hover:bg-blue-50"
															>
																{child.name}
															</a>
														) : (
															<Link
																onClick={() => close()}
																key={child.name}
																href={child.href}
																prefetch={false}
																className="block cursor-pointer whitespace-nowrap rounded-lg px-3 py-2 text-sm font-semibold leading-6 text-gray-900 hover:bg-blue-50"
															>
																{child.name}
															</Link>
														)
													)
												}
											</Popover.Panel>
										</Transition>
									</>
								)}
							</Popover>
						);
					})}
				</Popover.Group>

				<div className="flex items-center justify-end gap-x-2 md:flex-1 md:gap-x-4">
					<LanguageSwitcher
						className={'hidden md:block'}
						languageAlternates={languageAlternates}
					/>
					<div className="flex items-center">
						<AuthSignInSignOut />
					</div>
				</div>
			</nav>
		</header>
	);
}
